import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import PostList from "../components/post-list"
import { TagsFrame } from "../components/tags"

export const pageQuery = graphql`
  query ($skip: Int!, $tag: String, $limit: Int!) {
    allMdx(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date1], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date1(locale: "ja")
          title
          tags
          topImage {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.9
                transformOptions: { cropFocus: CENTER }
                width: 500
              )
            }
          }
        }
      }
    }
  }
`

const BlogTags = ({ data, location, pageContext }) => {
  const posts = data.allMdx.nodes
  const { tag, i, numPages, basePath } = pageContext
  const currentPage = i + 1
  const path = i === 0 ? basePath : basePath + `page${i + 1}`

  return (
    <Layout location={location}>
      <Seo
        title={i === 0 ? `#${tag}` : `#${tag}(${currentPage})`}
        slug={path}
      />
      <PostList
        posts={posts}
        currentPage={currentPage}
        numPages={numPages}
        basePath={basePath}
        tag={tag}
      >
        <TagsFrame title={`${tag}`} />
      </PostList>
    </Layout>
  )
}
export default BlogTags
